function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import { createElement } from "react";
import WebMediaQuery from "react-responsive";

export default function MediaQuery(_ref) {
  var resolution = _ref.devicePixelRatio,
      minResolution = _ref.minDevicePixelRatio,
      maxResolution = _ref.maxDevicePixelRatio,
      props = _objectWithoutProperties(_ref, ["devicePixelRatio", "minDevicePixelRatio", "maxDevicePixelRatio"]);

  return createElement(WebMediaQuery, Object.assign({
    resolution: resolution,
    minResolution: minResolution,
    maxResolution: maxResolution
  }, props));
}
